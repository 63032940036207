import { Component, OnInit } from '@angular/core';
// import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  visibilityMenu: boolean;
  doc_id_modelagem: string;

  constructor() {

    if (window.matchMedia('(min-width: 1024px)').matches) {
      this.visibilityMenu = true;
    }
    if (window.matchMedia('(max-width: 1023px)').matches) {
      this.visibilityMenu = false;
    }

    window.addEventListener('resize', (event) => {
        if (window.matchMedia('(min-width: 1024px)').matches) {
          this.visibilityMenu = true;
        }
        if (window.matchMedia('(max-width: 1023px)').matches) {
          this.visibilityMenu = false;
        }
    });

  }

  ngOnInit() {
  }

  closeMenu() {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      this.visibilityMenu = false;
    }
  }

  openCloseMenu() {
    this.visibilityMenu = !this.visibilityMenu;
  }

  isMobile() {
    if (navigator.userAgent.match(/Android/i)) return 'Android';
    if (navigator.userAgent.match(/BlackBerry/i)) return 'BlackBerry';
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) return 'iOS';
    if (navigator.userAgent.match(/Opera Mini/i)) return 'Opera';
    if (navigator.userAgent.match(/IEMobile/i)) return 'Windows';
  }

  shareWhatsApp() {
    var url = 'https://atelie.digital/ Para compartilhar modelagens efetue o login ;-)';
    this.doc_id_modelagem = sessionStorage.getItem('doc');
    if (this.doc_id_modelagem) {
      url = 'https://atelie.digital/modelagem/' + this.doc_id_modelagem;
    }

    if (this.isMobile() === 'Android' || this.isMobile() === 'iOS') {
      var WhatsAppWindow = window.open('https://api.whatsapp.com/send?text=atelie.digital ' + url, 'WhatsApp-popup', 'height=350,width=600');
    }
    else {
      var WhatsAppWindow = window.open('https://web.whatsapp.com/send?text=atelie.digital ' + url, 'WhatsApp-popup', 'height=350,width=600');
    }

    if (WhatsAppWindow.focus) { WhatsAppWindow.focus(); }
    return false;
  }

  shareTwitter() {
    var twitterWindow = window.open('https://twitter.com/share?url=https://atelie.digital/modelagem/' + this.doc_id_modelagem, 'twitter-popup', 'height=350,width=600');
    if (twitterWindow.focus) { twitterWindow.focus(); }
    return false;

    //https://twitter.com/intent/tweet?text=D%C3%A1%20uma%20olhada%20nessa%20reclama%C3%A7%C3%A3o%20contra%20Philco%20no%20%40ReclameAQUI&url=https%3A%2F%2Fwww.reclameaqui.com.br%2Fphilco%2Fma-assistencia-tecnica-com-de-pecas-ltda-me-philco_dRXpW5USrNQqI97w%2F
  }

  shareFacebook() {
    this.doc_id_modelagem = sessionStorage.getItem('doc');
    var url = 'https://atelie.digital/ Para compartilhar modelagens efetue o login ;-)';
    if (this.doc_id_modelagem) {
      url = 'https://atelie.digital/modelagem/' + this.doc_id_modelagem;
    }

    var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-popup', 'height=350,width=600');
    if (facebookWindow.focus) { facebookWindow.focus(); }
    return false;
  }

  shareGooglePlus() {
    //  var _this = this;
    var GooglePlusWindow = window.open('https://plus.google.com/share?url=' + 'https://atelie.digital/modelagem/' + this.doc_id_modelagem, 'googleplus-popup', 'height=350,width=600');
    if (GooglePlusWindow.focus) { GooglePlusWindow.focus(); }
    return false;
  }

  shareLinkedIn() {
    var url = 'https://atelie.digital/'
    var title = 'Tailor Made Now!';
    var text = 'https://atelie.digital/';
    window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + title, 'sharer', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  }


}
