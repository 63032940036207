import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//
// import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
//
import { IntroducaoComponent } from './introducao/introducao.component';
// import { ModelagemComponent } from './modelagem/modelagem.component';
import { SelecionarModeloComponent } from './selecionar-modelo/selecionar-modelo.component';
// import { SuasMedidasComponent } from './suas-medidas/suas-medidas.component';
// import { SelecionarMedidasComponent } from './selecionar-medidas/selecionar-medidas.component';

const routes: Routes = [

  {
    path: '',
    component: HomeComponent,
    children: [
    { path: '', component: IntroducaoComponent },
    { path: 'introducao', component: IntroducaoComponent },
    { path: 'contato', component: IntroducaoComponent },
    // { path: 'selecionar-medidas', component: SelecionarMedidasComponent },
    {
      path: 'selecionar-medidas', 
      // canActivate: [AuthGuard],
      loadChildren: () => import('./selecionar-medidas/selecionar-medidas.module').then(m => m.SelecionarMedidasModule)
    },    
    // { path: 'suas-medidas', component: SuasMedidasComponent },
    {
      path: 'suas-medidas', 
      // canActivate: [AuthGuard],
      loadChildren: () => import('./suas-medidas/suas-medidas.module').then(m => m.SuasMedidasModule)
    },    
    { path: 'selecionar-modelo', component: SelecionarModeloComponent },
    // { path: 'modelagem/:id', component: ModelagemComponent },
    // { path: 'modelagem', component: ModelagemComponent },
    {
      path: 'modelagem/:id', 
      // canActivate: [AuthGuard],
      loadChildren: () => import('./modelagem/modelagem.module').then(m => m.ModelagemModule)
    },    
    {
      path: 'modelagem', 
      // canActivate: [AuthGuard],
      loadChildren: () => import('./modelagem/modelagem.module').then(m => m.ModelagemModule)
    },    
    ]
  },
  // { path: 'login', component: LoginComponent }
  // ,{ path: '**', redirectTo: 'selecionar-modelo' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
