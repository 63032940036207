import { Component, OnInit } from '@angular/core';
import { modelos } from '../shared/mocks/modelos';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selecionar-modelo',
  templateUrl: './selecionar-modelo.component.html',
  styleUrls: ['./selecionar-modelo.component.scss']
})
export class SelecionarModeloComponent implements OnInit {

  _modelos = modelos;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  selecionarMedidas(modelo) {
    sessionStorage.setItem('modelo' , modelo);
    this.router.navigate(['/selecionar-medidas']);
  }

}
