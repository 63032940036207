import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//
import { HomeComponent } from './home/home.component';
// import { LoginComponent } from './login/login.component';
// import { LoginModule } from './login/login.module';

import { NavbarComponent } from './navbar/navbar.component';

// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//
// import { ModelagemComponent } from './modelagem/modelagem.component';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
// import { HttpClientModule } from '@angular/common/http';
//
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
// import { FirestoreSettingsToken } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
//
import { SelecionarModeloComponent } from './selecionar-modelo/selecionar-modelo.component';
// import { SelecionarMedidasComponent } from './selecionar-medidas/selecionar-medidas.component';
// import { SuasMedidasComponent } from './suas-medidas/suas-medidas.component';
import { IntroducaoComponent } from './introducao/introducao.component';
import { ContatoComponent } from './contato/contato.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    // LoginComponent,
    NavbarComponent,
    //
    // ModelagemComponent,
    SelecionarModeloComponent,
    // SelecionarMedidasComponent,
    // SuasMedidasComponent,
    IntroducaoComponent,
    ContatoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //
    // NgxExtendedPdfViewerModule,
    // HttpClientModule,
    //
    // FormsModule, 
    // ReactiveFormsModule,
    //
    // LoginModule,
    //
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app
    AngularFirestoreModule, // imports firebase/firestore
    AngularFireAuthModule, // imports firebase/auth
    // AngularFireStorageModule, // imports firebase/storage
    // AngularFireMessagingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
