import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  content_width_modelagem: string;

  constructor() { 
    window.scrollTo(0, 1);

    document.addEventListener('DOMContentLoaded', (event) => {
      this.changeBackgroundColor();
    });

    document.addEventListener('click', (event) => {
      this.changeBackgroundColor();
    });

    window.addEventListener('resize', (event) => {
      this.changeBackgroundColor();
    });

    window.addEventListener('scroll', (event) => {
      this.changeBackgroundColor();
    });

  }

  ngOnInit() {
  }

  changeBackgroundColor() {
    const url: string = location.href;
    this.content_width_modelagem = '';

    if (url.includes('/modelagem') || url.includes('/suas-medidas')) {
      if (window.matchMedia('(max-width: 375px)').matches) {
        this.content_width_modelagem = '_modelagem';
      }
    }

    if (url.includes('/contato')) {

      if (window.matchMedia('(max-width: 500px)').matches) {

        var objDiv = document.getElementById("home");
        // objDiv.scrollTop = objDiv.scrollHeight;

        // window.scrollTo(0, objDiv.scrollHeight);
      }

    }

  }

}
